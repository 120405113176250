import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route exact path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route exact path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        <Route exact path={`${APP_PREFIX_PATH}/admin/addadmin`} component={lazy(() => import(`./admin/CreateNewAdmin`))} />
        <Route exact path={`${APP_PREFIX_PATH}/admin/editadmin`} component={lazy(() => import(`./admin/EditAdmin`))} />
        <Route exact path={`${APP_PREFIX_PATH}/admin/projectrequest`} component={lazy(() => import(`./project_request`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project`} component={lazy(() => import(`./project`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/view/:projectdetails`} component={lazy(() => import(`./project/ViewProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/createproject/`} component={lazy(() => import(`./project/CreateProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/createuser/`} component={lazy(() => import(`./project/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/translation/createuser/`} component={lazy(() => import(`./project/translation/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/annotation/createuser/`} component={lazy(() => import(`./project/annotation/CreateUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/status/`} component={lazy(() => import(`./project/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/translation/status/`} component={lazy(() => import(`./project/translation/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/annotation/status/`} component={lazy(() => import(`./project/annotation/ProjectStatus`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/qa/`} component={lazy(() => import(`./project/AssignQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/translation/qa/`} component={lazy(() => import(`./project/translation/AssignQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/annotation/qa/`} component={lazy(() => import(`./project/annotation/AssignQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/external_qa/`} component={lazy(() => import(`./project/AssignEQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/cqa/`} component={lazy(() => import(`./project/ReAssignCQA`))} />
        <Route exact path={`${APP_PREFIX_PATH}/project/editproject/:id`} component={lazy(() => import(`./project/EditProject`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`./user`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user/edituser`} component={lazy(() => import(`./user/EditUser`))} />
        <Route exact path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route exact path={`${APP_PREFIX_PATH}/data-not-found`} component={lazy(() => import(`./DataNotFound/index`))} />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />

      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
